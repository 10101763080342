import { useEffect, useState, useRef } from 'react';
import 'leaflet-openweathermap/example/leaflet/leaflet.css'
import 'leaflet-openweathermap/leaflet-openweathermap.css'
import 'leaflet-openweathermap/example/files/map.css'
import L from 'leaflet'
import 'leaflet-openweathermap/example/leaflet/Permalink.js'
import 'leaflet-openweathermap/example/leaflet/Permalink.Layer.js'
import 'leaflet-openweathermap/example/leaflet/Permalink.Overlay.js'
import 'leaflet-openweathermap/leaflet-openweathermap.js'
import 'leaflet-openweathermap/example/leaflet/leaflet-languageselector.css'
import 'leaflet-openweathermap/example/leaflet/leaflet-languageselector.js'
import 'leaflet-openweathermap/example/files/map_i18n.js'
import 'leaflet-openweathermap/example/files/map.js'
import '../global.css'

const APP_ID = 'cdcb436b563e29634f88faf531a90905'

let markers = {}
let currentLayer = null

export default function Map({ weatherData, currentItem  }) {



  const map = useRef()


  let osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 18, attribution: '[insert correct attribution here!]'
  });

  let Stamen_Toner = L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.{ext}', {
    attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    subdomains: 'abcd',
    minZoom: 0,
    maxZoom: 20,
    ext: 'png'
  });

  //weather controller    
  let clouds = L.OWM.clouds({ showLegend: false, opacity: 0.3, appId: APP_ID });
  let city = L.OWM.current({ intervall: 15, opacity: 1.5, lang: 'en', appId: APP_ID });
  let rain = L.OWM.rain({ opacity: 0.5, intervall: 15, lang: 'en', appId: APP_ID });
  let rain_cls = L.OWM.rainClassic({ showLegend: false, opacity: 0.8, intervall: 15, lang: 'en', appId: APP_ID });
  let pressure = L.OWM.pressure({ intervall: 15, showLegend: false, opacity: 0.5, appId: APP_ID })
  let clouds_cls = L.OWM.cloudsClassic({ showLegend: false, opacity: 0.8, appId: APP_ID });
  let temperature = L.OWM.temperature({ intervall: 15, showLegend: false, opacity: 0.8, appId: APP_ID });
  let wind = L.OWM.wind({ showLegend: false, intervall: 15, opacity: 0.8, appId: APP_ID })

  function resetMarkers(){

    if (currentLayer) {
      currentLayer.remove()
    }

    Object.values(markers).map((item) => {

      map.current.removeLayer(item)
      
    })

    markers = {}

  }

  useEffect(() => {

    let mainMap = L.map('map', {

      center: new L.LatLng(38.2088747, -94.8546102),
      zoom: 5,
      layers: [osm],
      zoomControl: false,
      attributionControl: false,
      dragging: true,
      scrollWheelZoom: true,
      zoomSnap: 1

    });

    map.current = mainMap

  }, [])

  useEffect(() => {
    if (weatherData) {

      if (weatherData.items[currentItem].type == 'temperature'){
        
        resetMarkers()
        map.current.flyTo([38.2088747, -94.8546102], 5)
        let temperatureScene = L.layerGroup([Stamen_Toner, temperature]);
        temperatureScene.addTo(map.current);
        currentLayer = temperatureScene
        weatherData.items[currentItem].cities.map((item, index) => {

          markers[item.city] = new L.Marker([item.latitude, item.longitude], {
            icon: new L.DivIcon({
              className: 'my-div-icon',
              html: '<div class="card"><h3>' + item.city + '</h3><span>' + item.temperature + '°F</span></div>'
            })
          });

          markers[item.city].addTo(map.current);
        

        });

      } else if (weatherData.items[currentItem].type == 'precipitation'){

        resetMarkers()
      
        
        map.current.flyTo([38.2088747, -94.8546102], 5)
        let precipitationScene = L.layerGroup([Stamen_Toner,wind,clouds, rain_cls]);

        precipitationScene.addTo(map.current);
        currentLayer = precipitationScene

        weatherData.items[currentItem].cities.map((item, index) => {

          markers[item.city] = new L.Marker([item.latitude, item.longitude], {
            icon: new L.DivIcon({
              className: 'my-div-icon',
              html: '<div class="card"><h3>' + item.city + '</h3><span>' + item.wind + '</span></div>'
            })
          });

          markers[item.city].addTo(map.current);

        });


      } else if (weatherData.items[currentItem].type.indexOf('city_forecast') != -1){
        let item = weatherData.items[currentItem]
        //console.log("type:", item.type)
        resetMarkers()

        let precipitationScene = L.layerGroup([Stamen_Toner, temperature, clouds, rain]);

        precipitationScene.addTo(map.current);
        currentLayer = precipitationScene

        // markers[item.name] = new L.Marker([item.latitude, item.longitude], {
        //   icon: new L.DivIcon({
        //     className: 'my-div-icon',
        //     html: '<div class="card-city"></div>'
        //   })
        // });

        // markers[item.name].addTo(map.current);
        map.current.flyTo([item.latitude, item.longitude], 9)

      } else if (weatherData.items[currentItem].type.indexOf('city_week_forecast') != -1){


        let item = weatherData.items[currentItem]
        let imageCity = ""  
        
        console.log("type:", item.type)
        resetMarkers()      

        let precipitationScene = L.layerGroup([osm, wind]);

        precipitationScene.addTo(map.current);
        currentLayer = precipitationScene

        if(item.name == "Chicago"){

          imageCity = "https://images6.alphacoders.com/459/459857.jpg"
        

        } else if(item.name == "New York"){

          imageCity = "https://4kwallpapers.com/images/walls/thumbs_3t/7284.jpg"

        } else if(item.name == "Miami"){

          imageCity = "https://images8.alphacoders.com/468/468203.jpg"

        } else if(item.name == "Dallas"){

          imageCity = "https://wallpapercave.com/wp/wp4419107.jpg"

        } else if(item.name == "Los Angeles"){

          imageCity = "https://wallpapercave.com/wp/wp6860393.jpg"

        } else {

          imageCity= "https://wallpapercave.com/wp/wp5430320.jpg"
        }

       

        markers[item.name] = new L.Marker([item.latitude, item.longitude], {
          icon: new L.DivIcon({
            className: 'my-div-icon',
            html: '<img class="img-city-week" src="'+ imageCity +'">'
          })
        });

        markers[item.name].addTo(map.current);
        map.current.flyTo([item.latitude, item.longitude], 7)

      } else {
        resetMarkers()
      }

    }  


  }, [weatherData, currentItem]);


  return (
    <div id="map" style={{ alignSelf: "center", maxHeight: 1080, maxWidth: 1920, }}></div>
  );
}
