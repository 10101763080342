import axios from 'axios'

function getQueryStringParameter(parameterName) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(parameterName);
}


export async function loadWeatherData(previous=false) {
  let timestamp = ""
  let dt = new Date()
  let isoString = getQueryStringParameter("timestamp")
  console.log("isoString =>", isoString)
  if (isoString) {
    //TTS WITH SPOTIFY DJ VOICE CLONED: 2023-07-06T12:03:52.951Z
    //BARK WITH JUSSARA : 2023-07-06T10:03:52.951Z
    //BARK WITH SPOTIFY DJ: 2023-07-06T13:03:36.278Z
    timestamp = "?timestamp=" + isoString
  } else if (previous) { 
    dt.setHours(dt.getHours() - 1, 1, 1)
    timestamp = "?timestamp=" + dt.toISOString()
  }
  
  let result = await axios.get("https://climetv-api.airro.dev/scheduled_content/feed" + timestamp)
  return result.data.result
}