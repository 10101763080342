import React, { useState, useRef, useCallback, useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';
import { Text, View, StyleSheet, Button, Image, ImageBackground, TouchableOpacity } from 'react-native';
import Map from './components/map.web';
import TemperatureGradient from './components/TemperatureGradient';
import { Audio } from 'expo-av';
import { loadWeatherData } from './api';
import CityForecastPanel from './components/CityForecastPanel';
import SponsorPlayer from './components/SponsorPlayer';

let currentItem = 0
let totalItems = 0
let weatherData = null
let bgSound = null

export default function App() {

  const [sound, setSound] = useState();
  const [cityName, setCityName] = useState("Forevis");
  const [weatherState, setWeatherState] = useState(null)
  const [currentItemState, setCurrentItemState] = useState(-1)
  const [radarTitle, setRadarTitle] = useState("")

  const bgSoundUrl = "https://airguard-videos.s3.amazonaws.com/bg_music_1.mp3"

  const logo = "https://climeradar.com/_next/image?url=%2Ficons%2Fclime-logo-dark.svg&w=3840&q=75"


  const showFeedItem = (item) => {
    //console.log(item)
    if (item.audio) {
      console.log(item.text)
      playSound(item.audio)
      if (bgSound) {

        bgSound.setIsMutedAsync(false)

      }

    } else if (item.type == "sponsor") {
      //console.log("SHOW ADSENSE COMPONENT")
      bgSound.setIsMutedAsync(true)
      //nextItem()
    }
  }

  const nextItem = () => {
    currentItem++
    console.log("NEXT ITEM", currentItem + " of " + totalItems)
    if (currentItem >= totalItems) {
      console.log("RESET LOOP")
      currentItem = 0
      setCurrentItemState(currentItem)
      setTimeout(() => {
        loadShows()
      }, 3000)
    } else if (getCurrentItem()) {
      setCurrentItemState(currentItem)
      showFeedItem(getCurrentItem())
    }
  }

  const _onPlaybackStatusUpdate = (playbackStatus) => {
    if (playbackStatus.didJustFinish) {
      nextItem()
    }
  };

  function skipTrack() {
    if (sound) {
      console.log('Unloading Sound');
      sound.unloadAsync();
    }
    nextItem()
  }

  async function loadShows() {
    weatherData = await loadWeatherData()

    if (!bgSound) {
      playBgSound(bgSoundUrl, 0.03)
    }

    if (weatherData.items.length < 13) {
      weatherData = await loadWeatherData(true)
    }
    setWeatherState(weatherData)
    setCurrentItemState(0)
    totalItems = weatherData.items.length
    if (weatherData) {
      showFeedItem(getCurrentItem())
    }
  }

  async function playSound(path, volume = 1.0) {
    if (sound) {
      console.log('Unloading Sound');
      await sound.unloadAsync();
    }
    console.log('Loading Sound [' + currentItem + "] => " + path);
    const { sound } = await Audio.Sound.createAsync(path, { volume: volume });
    sound.setOnPlaybackStatusUpdate(_onPlaybackStatusUpdate);
    setSound(sound);

    console.log('Playing Sound');
    await sound.playAsync();
  }

  async function playBgSound(path, volume) {
    if (bgSound) {
      console.log('Unloading Sound');
      await bgSound.unloadAsync();
    }
    const { sound } = await Audio.Sound.createAsync(path, { volume: volume, isLooping: true });
    bgSound = sound
    //setBgSound(sound);

    await sound.playAsync();
  }

  function capitalizeWord(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  function formatType(type) {

    let arr

    if(type.indexOf("city_forecast") != -1 ){
      arr = type.replace("city_forecast", "").split("_")
      arr = arr.map((word) => {
        return capitalizeWord(word)
      })

    }else if(type.indexOf("city_week_forecast") != -1){
      arr = type.replace("city_week_forecast", "").split("_")
      arr = arr.map((word) => {
        return capitalizeWord(word)
      })

    }else if(type == "precipitation"){
      arr = type.replace("city_week_forecast", "").split("_")
      arr = arr.map((word) => {
        return capitalizeWord(word)
      })

    } else {
      arr = type.replace("city_week_forecast", "").split("_")
      arr = arr.map((word) => {
        return capitalizeWord(word)
      })

    }
   

    return arr.join(" ")
  }

  function startShow() {
    loadShows()
  }

  const getCurrentItem = () => {
    if (weatherData) {
      return weatherData.items[currentItem]
    }
  }

  useEffect(() => {
    if (weatherState) {
      const title = getCurrentItem().show
      if (title == "national_radar") {
        setRadarTitle(formatType(getCurrentItem().type))
      } else if (title == "regional_radar") {
        setRadarTitle(formatType(getCurrentItem().type) + " Today")
      } else if (title == "regional_week_forecast") {
        setRadarTitle(formatType(getCurrentItem().type) + " 7-Day Forecast")
      } else if (getCurrentItem().type == "sponsor") {
        setRadarTitle(title)
      } else {
        setRadarTitle("")
      }
    }
  }, [weatherData, currentItem]);



  return (
    <View style={styles.container}>
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center", width: 100000, height: 100000, maxHeight: 1080, maxWidth: 1920, }}>
        <View style={{ backgroundColor: "white", width: "100%", position: "absolute", top: 0, left: 0, height: 145, zIndex: 999 }}>
          <ImageBackground source={{ uri: logo }} resizeMode='contain' style={styles.logo} />
          <Text style={{ fontSize: 50, color: "#EA4343", fontWeight: 800, marginTop: (weatherState && getCurrentItem().type == "sponsor") ? 30 : 10, alignSelf: "center" }}>{radarTitle}</Text>
          {weatherState && getCurrentItem().type == "precipitation" &&
            <TemperatureGradient leftText={"Light"} rightText={"Heavy"} showNumbers={false}/>
          }
          {weatherState && getCurrentItem().type == "temperature" &&
            <TemperatureGradient leftText={"Cold"} rightText={"Hot"} showNumbers={true}/>
          }
          {weatherState && getCurrentItem().type.indexOf('city_forecast') != -1 &&
            <TemperatureGradient leftText={"Cold"} rightText={"Hot"} showNumbers={true}/>
          }

          {weatherState && getCurrentItem().type.indexOf('city_week_forecast') != -1 &&
            <TemperatureGradient leftText={"Cold"} rightText={"Hot"} showNumbers={true}/>
          }

        </View>
        <View style={{ backgroundColor: "rgba(255,255,255,0.5)", width: "100%", position: "absolute", bottom: 0, left: 0, height: 130, zIndex: 999 }}>
          {weatherState && getCurrentItem().type &&
            <Text style={{ fontSize: 32, color: "black", fontWeight: 800, alignSelf: "left", paddingTop: 50 }}>{/* formatType(getCurrentItem().type)*/}</Text>
          }
          {weatherState && getCurrentItem().type.indexOf('city_forecast') != -1 &&
            <CityForecastPanel weatherData={weatherState} currentItem={currentItemState} />

          }
          {weatherState && getCurrentItem().type.indexOf('city_week_forecast') != -1 &&
            <CityForecastPanel weatherData={weatherState} currentItem={currentItemState} />

          }
        </View>
        {!sound &&
          <TouchableOpacity style={styles.button} onPress={startShow}>
            <Text style={styles.buttonText}>Start Show</Text>
          </TouchableOpacity>
        }
        <Map weatherData={weatherState} currentItem={currentItemState} style={{ position: "relative", zIndex: 0 }} />
        {weatherState && getCurrentItem().type.indexOf('sponsor') != -1 &&
          <SponsorPlayer video={getCurrentItem().video} image={getCurrentItem().image} nextItem={nextItem} />

        }
      </View>
      {sound &&

        <TouchableOpacity style={[{backgroundColor:"#1C72EB", width: 100, padding:10, borderRadius:60 }]} onPress={skipTrack}>
          <Text style={[styles.buttonText,{fontSize:18, textAlign:"center"}]}>Skip</Text>
        </TouchableOpacity>
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgb(255, 255, 255)',
    alignItems: 'center',
    justifyContent: 'center',
    justifyContent: "center",
    alignItems: "center"
  },
  logo: {
    position: "absolute",
    width: 250,
    height: 75,
    top: 30,
    left: 30
  },
  button: {
    flex: 1,
    position: "absolute",
    backgroundColor: "#1C72EB",
    width: 250,
    height: 75,
    borderRadius: 60,
    zIndex: 999,
    justifyContent: "center",
    alignItems: "center"
  },
  buttonText: {
    color: "white",
    fontSize: 36,
    fontWeight: "bold"
  },
});

