import React from 'react';
import { View, Text, StyleSheet, Dimensions } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

const TemperatureGradient = ({leftText, rightText, showNumbers}) => {
  const temperatureColors = [
    '#0000ff', // -40°C Blue
    '#004cff', // -35°C Blue
    '#0099ff', // -30°C Light Blue
    '#00ffff', // -25°C Cyan
    '#00ff99', // -20°C Blue-Green
    '#00ff00', // -15°C Green
    '#7cff00', // -10°C Light Green
    '#b6ff00', // -5°C Green-Yellow
    '#84fe7a', // 0°C Green
    '#feff00', // 5°C Yellow
    '#ffcb00', // 10°C Light Yellow
    '#ff9600', // 15°C Light Orange
    '#ff6200', // 20°C Orange
    '#ff2d00', // 25°C Light Red
    '#ff0000', // 30°C Red
    '#cc0000', // 35°C Dark Red
    '#990000', // 40°C Wine Red
  ]
  const temperatureRange = temperatureColors.map((_, index) => (index - 8) * 5);

  function convertCelsiusToFahrenheit(celsius) {
    return (celsius * 9/5) + 32;
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{leftText}</Text>
      <LinearGradient
        colors={temperatureColors}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        style={styles.gradient}
      >
        {temperatureRange.map((temp, index) => (
          <Text key={index} style={styles.text}>
            {showNumbers ? convertCelsiusToFahrenheit(temp) +  "°" : ""}
          </Text>
        ))}
      </LinearGradient>
      <Text style={styles.title}>{rightText}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
    alignSelf:"center",
    flexDirection:"row"
  },
  gradient: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 30,
    paddingHorizontal:5,
    borderRadius: 5,
    width: 1000,
    alignItems:"center"
  },
  text: {
    color: 'white',
    fontSize: 16,
    fontWeight:"bold",
    textAlign:"center",
    textShadowColor: 'rgba(0, 0, 0, 1.0)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  },
  title: {
    color: 'black',
    fontSize: 22,
    fontWeight:'bold',
    marginHorizontal:10
  },
  
});

export default TemperatureGradient;
