import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, Button, Image, ImageBackground } from 'react-native';
import { Video, ResizeMode } from 'expo-av';

let timer = null

export default function SponsorPlayer({ video, image, nextItem }) {

    const videoRef = useRef(null);
    const [status, setStatus] = useState({});

    useEffect(() => {

        if (videoRef.current && video) {
            videoRef.current.playAsync()
            return (() => {
                if (videoRef.current) {
                    videoRef.current.stopAsync()
                }
            })
        } else if (image) {

            timer = setTimeout(nextItem, 7000)
            return (() => {
                if (timer) {
                    clearTimeout(timer)
                }
            })
        }

    }, [])

    useEffect(() => {

        if (status.didJustFinish) {
            nextItem()
        }
        //console.log(status)

    }, [status])

    return (
        <View style={styles.container}>
            {video &&
                <Video
                    ref={videoRef}
                    style={styles.video}
                    source={{
                        uri: video,
                    }}
                    resizeMode={ResizeMode.STRETCH}
                    onPlaybackStatusUpdate={status => setStatus(() => status)}
                />
            }
            {image &&

                <ImageBackground source={{ uri: image }} resizeMode={"cover"} style={styles.image} />

            }
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        position: "absolute",
        backgroundColor: 'rgba(0,0,0,0.8)',
        alignItems: "center",
        top: 140,
        left: 0,
        zIndex: 999
    },
    video: {
        position: "relative",
        alignSelf: 'center',
        width: 1920,
        height: 850,
        top: 20,
        left: 1920 / 6
    },
    image: {
        position: "relative",
        alignSelf: 'center',
        width: 1920,
        height: 800,
        top: 20,
        left: 0
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
