import React, { useState, useRef, useCallback, useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';
import { Text, View, StyleSheet, Button } from 'react-native';
import moment from 'moment';
import '../global.css'

const CityForecastPanel = ({ weatherData, currentItem }) => {

  const [cards,setcards] = useState([])

  useEffect(() => {

    if (weatherData) {

      //console.log("OBJ",weatherData.items[currentItem] )

      if (weatherData.items[currentItem].type.indexOf('city_forecast') != -1) {

        const cardsObj = weatherData.items[currentItem].forecasts

        //console.log("cards:", cardsObj)

        const cardsLoop = Object.values(cardsObj).map((card, index) => {
          const date = new Date(card.start);
          const hourTitle = moment(date).format("h a")
          
          return (           
              <div className='forecard' key={index}>
                <span className="title">{hourTitle}</span>
                <img src={card.icon} />
                <span className="temp">{card.temp}</span>
                <div className='wind-hum-container'>
                  <span className="wind-hum">W: {card.wind} | {card.windDirection}</span>
                  <span className="wind-hum">H: {card.humidity}</span>
                </div>
              </div>            
          )
        })

        setcards(cardsLoop)
      } else if (weatherData.items[currentItem].type.indexOf('city_week_forecast') != -1) {

        const cardsObj = weatherData.items[currentItem].forecasts
       

        //console.log("7week:", cardsObj)

        const cardsLoop = Object.values(cardsObj).map((card, index) => {
          const date = new Date(card.start);
          const hourTitle = moment(date).format("h a") 
          let icon = card.icon

          icon = icon.replace("/night/", "/day/")
          
          return (           
              <div className='forecard' key={index}>
                <span className="title">{card.weekDayAbbv}</span>
                <img src={icon} />
                <span className="temp">{card.temp}</span>
                <div className='wind-hum-container'>
                  <span className="wind-hum">W: {card.wind} - {card.windDirection}</span>
                  <span className="wind-hum">H: {card.humidity}</span>
                </div>
              </div>            
          )
        })

        setcards(cardsLoop)
      }
    }

  }, [weatherData, currentItem]);

  return (
    <div className='forecast-panel' >{cards}</div>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
    alignSelf: "center",
    flexDirection: "row"
  },
  text: {
    color: '#000',
    fontSize: 14,
    textAlign: "center"
  },
  title: {
    color: '#fff',
    fontSize: 22,
    fontWeight: 'bold',
    marginHorizontal: 10
  },

});

export default CityForecastPanel;
